<template>

  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div v-if="!isSearchAreaShow" class="header-component">
      <!-- header-title -->
      <div class="header-column header-title">
        FAQ
      </div>
      <!-- //header-title -->
      <!-- header-util -->
      <div class="header-column header-util right">
        <div class="util util-search">
          <a href="javascript:" @click="isSearchAreaShow = true" class="util-actions util-actions-search">
            <i class="icon-search"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>

    <!-- 검색영역  -->
    <div v-else class="header-component">
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-back">
          <a href="javascript:" @click="isSearchAreaShow = false" class="util-actions util-actions-back">
            <i class="icon-history-back"></i>
          </a>
        </div>
        <div class="util util-search-input">
          <input type="text" v-model="search.faqTitle" placeholder="FAQ 검색">
        </div>
      </div>
      <!-- //header-util -->
      <!-- header-util -->
      <div class="header-column header-util right">
        <div class="util util-search">
          <a href="javascript:" @click="clickSearch" class="util-actions util-actions-search">
            <i class="icon-search"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-helpdesk">
    <!-- main-header -->
    <div v-if="!isSearchAreaShow" class="main-header">
      <!-- main-header > header-snb -->
      <div class="header-snb">
        <nav class="snb">
          <ul class="snb-list">
            <li v-for="(item, idx) in faqClassCdDcd" :key="item.cd" class="snb-item" :class="{'is-active' : idx === 0}">
              <button @click="selectClass(item.cd, idx)">{{ item.cdNm }}</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <!-- //main-header -->

    <!-- main-content -->
    <div class="main-content main-component">

      <div v-if="items.length === 0 && searchRslt > 0" class="search-result">
        <!-- result-empty -->
        <div class="result-empty">
          <img src="@/assets/lxp/mobile/images/_common/img_empty.png">
          <p class="text">검색결과가 없어요</p>
        </div>
        <!-- //result-empty -->
      </div>

      <div v-else class="faq-list-container">
        <ul class="faq-list">
          <li class="faq-list-item" v-for="(item, idx) in items" :key="idx">
            <div class="kb-collapse" :class="{ 'is-active' : showFaqIdx === idx }">
              <div class="kb-collapse-toggle kb-collapse-row faq-row">
                <a href="javascript:" @click="clickFaqItem(idx)" class="faq-link collpse-toggle-action"></a>
                <div class="kb-collapse-column">
                  <div class="faq-category category"><span class="text">{{item.faqClassNm}}</span></div>
                  <div class="faq-title title"><span class="text">{{item.faqTitle}}</span></div>
                </div>
                <div class="faq-arrow kb-collapse-icon"><i class="icon-arrow"></i></div>
              </div>
              <div class="kb-collapse-target kb-collapse-row faq-row">
                <div class="kb-collapse-column">

                  <div class="faq-category category"><span class="text">답변</span></div>
                  <div class="faq-content content">
                      <span class="text" v-html="item.faqCn"></span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
      </div>

    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->

</template>

<script>
import {computed, onBeforeMount, onMounted, reactive, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {
  getCdNm,
  getItems,
  getPaging,
  initPaging, initProgressBar,
  lengthCheck,
  setParamsByQueryString,
  timestampToDateFormat,
} from '@/assets/js/util';
import {ACT_GET_HELP_FAQ_LIST} from '@/store/modules/help/help';
import CommonPaginationFront from '@/components/CommonPaginationFront';

export default {
  name: 'HelpMobileFaq',
  components: {CommonPaginationFront},
  setup() {
    const isCategoryActive = ref(false);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const paging = ref(initPaging(route));
    const items = ref([{}]);
    const search = reactive({
      faqTitle:'',
      faqClassCdDcd: ''
    });

    const faqClassCdDcd = computed(() => store.state.code.codes.filter(code => code.cd.substring(4) !== '000' && code.cd.indexOf('2023') === 0));

    const faqClassNm = computed(() => {
      if (search.faqClassCdDcd && search.faqClassCdDcd.length > 0) return getCdNm(store, search.faqClassCdDcd);
      return '카테고리';
    });

    const showFaqIdx = ref(-1);

    const isSearchAreaShow = ref(false);
    const searchRslt = ref(0);

    // 목록, 검색페이지 이동시 데이터 세팅
    watch(() => isSearchAreaShow.value, () => {
      if(isSearchAreaShow.value){
        items.value = [];
        paging.value.totalCount = 0;
      }else{
        search.faqTitle = '';
        search.faqClassCdDcd = '';
        searchRslt.value = 0;
        if(Object.keys(route.query).length === 0) getHelpFaqList();
        else pagingFunc();
      }
    })

    const getHelpFaqList = () => {
      store.dispatch(`help/${ACT_GET_HELP_FAQ_LIST}`, {
        faqTitle: search.faqTitle,
        faqClassCdDcd: search.faqClassCdDcd,
        stt: '00',
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if (lengthCheck(res)) {
          // document.querySelectorAll('.kb-collapse').forEach(obj => obj.classList.remove('is-active'));
          items.value = getItems(res);
          paging.value = getPaging(res);
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    }
    getHelpFaqList();
    faqClassCdDcd.value.unshift({cd: '', cdNm: '전체'})

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      if(search.faqTitle) query.faqTitle = search.faqTitle;
      if(search.faqClassCdDcd) query.faqClassCdDcd = search.faqClassCdDcd;
      router.push({query: query});
    };

    const clickSearch = () => {
      pagingFunc(1, 10);
      searchRslt.value++;
    };

    const getDateFormat = (format, value) => {
      if(value){
        return timestampToDateFormat(value, format ? format : 'yyyy.MM.dd');
      }
      return '-';
    };

    const selectClass = (dcd, idx) => {
      // if (dcd === '2023001') dcd =  '';
      search.faqClassCdDcd = dcd;
      pagingFunc(1, 10);
      document.querySelectorAll('.snb-item').forEach(x => x.classList.remove('is-active'))
      document.querySelectorAll('.snb-item')[idx].classList.add('is-active');
    };

    const clickFaqItem = (idx) => {
      if (showFaqIdx.value === idx) {
        showFaqIdx.value = -1;
      } else {
        showFaqIdx.value = idx;
      }
    };

    watch(() => route.query, () => {
      setParamsByQueryString(route, 'HelpFaq', paging, search, true);
      getHelpFaqList();
      initProgressBar();
    });

    onBeforeMount(() => {
      setParamsByQueryString(route, 'HelpFaq', paging, search, true);
    });

    onMounted(() => {
      getHelpFaqList();
    });

    return {
      isCategoryActive,
      items,
      paging,
      search,
      isSearchAreaShow,
      searchRslt,

      faqClassCdDcd,
      faqClassNm,
      selectClass,

      showFaqIdx,
      clickFaqItem,

      pagingFunc,
      clickSearch,
      getDateFormat,
    };
  },
};
</script>
